<template>
	<div class="search-jsj-tatle">
		<div style="display: flex;">
			<el-input
			  v-model="key"
			  placeholder="请输入搜索关键词"
			  class="input-with-select"
			>
			  <template #append>
			    <el-button @click="fetchOrders" icon="Search" />
			  </template>
			</el-input>
		</div>
	</div>
	<el-table :data="tableData" style="width: 100%" height="480">
	    <el-table-column fixed prop="spmc" label="商品名称" width="300" />
	    <el-table-column fixed prop="yjg" label="原价格" width="120" />
	    <el-table-column prop="yhjg" label="优惠价格" width="120" />
	    <el-table-column prop="kssj" label="有效期开始" width="200" />
		<el-table-column prop="jssj" label="有效期结束" width="200" />
		<el-table-column prop="zt" label="状态" width="120" />
	    <el-table-column fixed="right" label="操作" width="*">
	      <template #default="scope">
	        <el-button link type="primary" size="small" @click="handleDtClick(scope.row)">详情</el-button>
			<el-button link type="primary" size="small" @click="grantCoupon(scope.row)">上架/下架</el-button>
			<el-button link type="primary" size="small" @click="handlePrice(scope.row)">价格</el-button>
			<el-button link type="primary" size="small" @click="deleteData(scope.row)">删除</el-button>
	      </template>
	    </el-table-column>
	</el-table>
	<div class="pagination-custom">
		<el-pagination :page-size="20" @current-change="changePageNo" background layout="pager" :total="total" />
	</div>
	<el-dialog
		@close="closeDtDialog"
	    v-model="jsjDialogVisible"
	    title="商品详情"
	    width="70%"
	    destroy-on-close
	    center
		:modal="false"
	>
		<DataForm />
		<template #footer>
		  <span class="dialog-footer">
		    <el-button @click="closeDtDialog">关闭</el-button>
			<el-button v-if="forbidEdit" type="primary" @click="editForm">编辑</el-button>
		    <el-button v-if="!forbidEdit" type="primary" @click="saveForm">保存</el-button>
		  </span>
		</template>
	</el-dialog>
	<el-dialog
		@close="closePriceDialog"
	    v-model="priceDialogVisible"
	    title="价格详情"
	    width="50%"
	    destroy-on-close
	    center
		:modal="false"
	>
		<el-table :data="priceTableDatas" style="width: 100%" height="480">
			<el-table-column fixed prop="spmc" label="商品名称" width="280" />
			<el-table-column fixed prop="yjg" label="原价格" width="80" />
			<el-table-column prop="jg" label="优惠价格" width="100" />
			<el-table-column prop="rq" label="日期" width="200" />
			<el-table-column fixed="right" label="操作" width="*">
			  <template #default="scope">
				<el-button link type="primary" size="small" @click="handleDtPrice(scope.row)">编辑</el-button>
			  </template>
			</el-table-column>
		</el-table>
	</el-dialog>
	<el-dialog
	    v-model="jgDialogVisible"
	    title="修改价格"
	    width="50%"
	    destroy-on-close
	    center
		:modal="false"
	>
		<el-input class="w-50 m-2" v-model="newJg" />
		<el-input class="w-50 m-2" v-model="newYjg" />
		<template #footer>
		  <span class="dialog-footer">
		    <el-button @click="closeJgDialog">关闭</el-button>
		    <el-button type="primary" @click="saveYkj">保存</el-button>
		  </span>
		</template>
	</el-dialog>
</template>

<script setup>
	import { ref,provide,inject,onMounted } from 'vue';
	import axios from 'axios';
	import DataForm from './goodInfo.vue';
	import { useMemberStore } from '../../stores';
	import { ElMessage } from 'element-plus'
	const userStore = useMemberStore();
	const jgDialogVisible = ref(false)
	const closeJgDialog=()=>{
		jgDialogVisible.value = false;
	}
	const priceBillId = ref(-1)
	const saveYkj=async()=>{
		const response = await axios.post('/goods/manage/savePrice', {
		  billid:priceBillId.value,
		  newYjg:newYjg.value,
		  newJg:newJg.value
		}, {
		  headers: {
		    'Content-Type': 'application/json',
			Authorization: `${userStore.profile?.token}`,
		  }
		});
		if(response.data.status===0){
			jgDialogVisible.value = false
			ElMessage({
				message: '修改成功!',
				type: 'success',
			});
			const res = await axios.post('/goods/manage/queryPriceList', {
				goodid:goodid.value,
			}, {
			  headers: {
			    'Content-Type': 'application/json',
				Authorization: `${userStore.profile?.token}`,
			  }
			});
			if(res.data.status===0){
				priceTableDatas.value = res.data.data
			}
			priceBillId.value = -1
			newYjg.value = 0
			newJg.value = 0
		}
	}
	const priceDialogVisible = ref(false)
	const closePriceDialog=()=>{
		priceDialogVisible.value = false;
	}
	const newJg = ref(0)
	const newYjg = ref(0)
	const handleDtPrice=(event)=>{
		priceBillId.value = event.id
		newYjg.value = event.yjg
		newJg.value = event.jg
		jgDialogVisible.value = true
	}
	const pageNo = ref(1);
	const pageSize = ref(20);
	const total = ref(0);
	const key = ref('');
	const tableData = ref([]);
	const jsjDialogVisible = ref(false);//详情
	const miniUserDatas = ref([])
	const formffyhq = ref(null)
	const newBz = ref('');
	const billid = ref('');
	const forbidEdit = ref(true);
	provide('forbidEdit', forbidEdit);
	provide('billid', billid);
	const priceTableDatas = ref([])
	const goodid = ref(-1)
	const handlePrice=async(event)=>{
		goodid.value = event.id;
		priceTableDatas.value = [];
		const response = await axios.post('/goods/manage/queryPriceList', {
			goodid:event.id,
		}, {
		  headers: {
		    'Content-Type': 'application/json',
			Authorization: `${userStore.profile?.token}`,
		  }
		});
		if(response.data.status===0){
			priceTableDatas.value = response.data.data
		}
		priceDialogVisible.value = true;
	}
	const orderForm = ref({
		spmc:'',//商品名称
		yjg:'',//原价格
		yhjg:'',//优惠价格
		carousel:[],//轮播图
		kssj:'',//可以预定开始时间
		jssj:'',//可以预定结束时间
		spxq:'<p>hello</p>',//商品描述
		zt:'1'//上架、下架
	})
	provide('orderForm', orderForm);
	onMounted(async()=>{
		const response = await axios.post('/goods/manage/queryList', {
		  pageNo:pageNo.value,
		  pageSize:pageSize.value,
		}, {
		  headers: {
		    'Content-Type': 'application/json',
			Authorization: `${userStore.profile?.token}`,
		  }
		});
		if(response.data.status===0){
			tableData.value = response.data.data;
			total.value = response.data.total;
		}
	})
	const fetchOrders = async () => {
		const response = await axios.post('/goods/manage/queryList', {
		  key:'spmc',
		  value:key.value,
		  pageNo:pageNo.value,
		  pageSize:pageSize.value,
		}, {
		  headers: {
		    'Content-Type': 'application/json',
			Authorization: `${userStore.profile?.token}`,
		  }
		});
		if(response.data.status===0){
			tableData.value = response.data.data;
			total.value = response.data.total;
		}else{
			tableData.value = [];
			total.value = 0;
		}
	};
	const grantCoupon=async(e)=>{
		const res = await axios.post('/goods/manage/freshStatus', {
			billid:e.id,
			zt:e.zt==='上架'?'1':'0'
		}, {
		  headers: {
		    'Content-Type': 'application/json',
			Authorization: `${userStore.profile?.token}`,
		  }
		});
		if(res.data.status===0){
			fetchOrders();
		}
	}
	const handleDtClick = (e)=>{
		console.log(e)
		billid.value = e.id;
		jsjDialogVisible.value = true;
	}
	const deleteData=async(e)=>{
		const response = await axios.post('/goods/manage/delData', {
		  billid:e.id,
		}, {
		  headers: {
		    'Content-Type': 'application/json',
			Authorization: `${userStore.profile?.token}`,
		  }
		});
		if(response.data.status===0){
			ElMessage({
				message: '删除成功!',
				type: 'success',
			})
			fetchOrders()
		}
		
	}
	const changePageNo = (val) => {
		pageNo.value = val;
		fetchOrders();
	}
	const closeDtDialog=()=>{
		forbidEdit.value = true;
		jsjDialogVisible.value = false;
	}
	const editForm = ()=>{
		forbidEdit.value = false;
	}
	const saveForm = async () =>{
		const response = await axios.post('/goods/manage/saveOrUpdate', {
		  billid:billid.value,
		  ...orderForm.value
		}, {
		  headers: {
		    'Content-Type': 'application/json',
			Authorization: `${userStore.profile?.token}`,
		  }
		});
		if(response.data.status===0){
			ElMessage({
				message: '信息保存成功!',
				type: 'success',
			})
			fetchOrders()
		}else{
			ElMessage.error('哦,信息保存失败!')
		}
		jsjDialogVisible.value = false;
	}
</script>

<style scoped>
	.pagination-custom{
		display: flex;
		justify-content: flex-end;
		align-items: center;
		margin: 10px 0;
	}
	.search-jsj-tatle{
		float: right;
		width: 50%;
		margin: 0 0 10px 0;
	}
	.dialog-cond-dt{
		margin: 0 0 0 20px;
	}
	.dialog-cond-label{
		margin: 0 5px;
	}
</style>