<template>
	<div style="display: flex;margin: 10px 4%;height: 600px;">
	  <!-- 左边接机表格 -->
	  <div style="width: 50%; padding: 4px 0 10px 0;border: 2px solid #000;">
		<div style="text-align: center;border-bottom: 3px solid #000;"><text style="font-weight: bold;">接机</text></div>
		<div style="display: flex; font-weight: bold; border-bottom: 3px solid #000;text-align: center;">
		  <text style="width: 20%;">航班号</text>
		  <text style="width: 20%;">预计日期</text>
		  <text style="width: 20%;">预计时间</text>
		  <text style="width: 20%;">实际时间</text>
		  <text style="width: 20%;">航站楼信息</text>
		</div>
		<div v-for="(flight, index) in receive" :key="index" style="line-height: 28px;text-align: center;display: flex; border-bottom: 1px solid #ccc;">
		  <text style="width: 20%;">{{ flight.flightNumber }}</text>
		  <text style="width: 20%;">{{ flight.estimatedDate }}</text>
		  <text style="width: 20%;">{{ flight.estimatedTime }}</text>
		  <text style="width: 20%;">{{ flight.actualTime }}</text>
		  <text style="width: 20%;">{{ flight.terminal }}</text>
		</div>
	  </div>

	  <!-- 右边送机表格 -->
	  <div style="width: 50%; padding: 4px 0 10px 0;border: 2px solid #000;">
		<div style="text-align: center;border-bottom: 3px solid #000;"><text style="font-weight: bold;">送机</text></div>
		<div style="display: flex; font-weight: bold; border-bottom: 3px solid #000;text-align: center;">
		  <text style="width: 10%;">航班号</text>
		  <text style="width: 15%;">预计日期</text>
		  <text style="width: 15%;">预计时间</text>
		  <text style="width: 15%;">实际时间</text>
		  <text style="width: 15%;">航站楼信息</text>
		  <text style="width: 15%;">办理登机牌</text>
		  <text style="width: 15%;">登机口</text>
		</div>
		<div v-for="(flight, index) in send" :key="index" style="line-height: 28px;text-align: center;display: flex; border-bottom: 1px solid #ccc;">
		  <text style="width: 10%;">{{ flight.flightNumber }}</text>
		  <text style="width: 15%;">{{ flight.estimatedDate }}</text>
		  <text style="width: 15%;">{{ flight.estimatedTime }}</text>
		  <text style="width: 15%;">{{ flight.actualTime }}</text>
		  <text style="width: 15%;">{{ flight.terminal }}</text>
		  <text style="width: 15%;">{{ flight.chkinrange }}</text>
		  <text style="width: 15%;">{{ flight.gatenumber }}</text>
		</div>
	  </div>
	</div>
</template>

<script setup>
  import { ref,provide,inject,onMounted } from 'vue';
  import axios from 'axios';
  import { ElMessage } from 'element-plus'
  const send = ref([])
  const receive = ref([])
  onMounted(async()=>{
    const now = new Date();
    let date = new Date(now.getTime() - 12 * 60 * 60 * 1000); // 减去12小时的毫秒数
    let year = date.getFullYear();
    let month = String(date.getMonth() + 1).padStart(2, '0'); // 月份从0开始计数，所以加1
    let day = String(date.getDate()).padStart(2, '0');
    let hour = String(date.getHours()).padStart(2, '0')
    let mintue = String(date.getMinutes()).padStart(2, '0')
    const start = `${year}-${month}-${day} ${hour}:${mintue}:00`
	const searchStart = `${year}${month}${day}${hour}${mintue}`
    date = new Date(now.getTime() + 24 * 60 * 60 * 1000); // 减去12小时的毫秒数
    year = date.getFullYear();
    month = String(date.getMonth() + 1).padStart(2, '0'); // 月份从0开始计数，所以加1
    day = String(date.getDate()).padStart(2, '0');
    hour = String(date.getHours()).padStart(2, '0')
    mintue = String(date.getMinutes()).padStart(2, '0')
    const end = `${year}-${month}-${day} ${hour}:${mintue}:00`
	const searchEnd = `${year}${month}${day}${hour}${mintue}`
    const res = await axios.post('/flight/info/queryFlightDynamic', {
	  start,
	  end,
	  searchStart,
	  searchEnd
	}, {
	  headers: {
		'Content-Type': 'application/json',
	  }
	})
    if(res.data.status===0){
      send.value = res.data.send;
      receive.value = res.data.receive;
    }
  })
</script>
