<template>
	<div class="search-jsj-tatle">
		<div style="display: flex;">
			<el-input
			  v-model="key"
			  placeholder="请输入搜索关键词"
			  class="input-with-select"
			>
			  <template #append>
			    <el-button @click="fetchOrders" icon="Search" />
			  </template>
			</el-input>
		</div>
	</div>
	<el-table :data="tableData" style="width: 100%" height="480">
	    <el-table-column fixed prop="atddh" label="敖途订单号" width="130" />
	    <el-table-column prop="xdsj" label="下单时间" width="200" />
	    <el-table-column prop="sysj" label="使用时间" width="200" />
	    <el-table-column prop="jc" label="机场" width="60" />
	    <el-table-column prop="jslx" label="接送" width="60" />
	    <el-table-column prop="krscx" label="人数/车型" width="120" />
	    <el-table-column prop="ddzt" label="状态" width="80" />
	    <el-table-column prop="fjfw" label="附加服务" width="200" />
	    <el-table-column prop="bz" label="备注" width="120" />
	    <el-table-column fixed="right" label="操作" width="*">
	      <template #default="scope">
			<el-button link type="primary" size="small" @click="deleteData(scope.row)">删除</el-button>
	      </template>
	    </el-table-column>
	</el-table>
	<div class="pagination-custom">
		<el-pagination :page-size="20" @current-change="changePageNo" background layout="pager" :total="total" />
	</div>
</template>

<script setup>
	import { ref,provide,inject,onMounted } from 'vue';
	import axios from 'axios';
	import { useMemberStore } from '../../stores';
	import { ElMessage } from 'element-plus'
	const userStore = useMemberStore();
	const pageNo = ref(1);
	const pageSize = ref(20);
	const total = ref(0);
	const searchType = ref(0);
	const key = ref('');
	const type = ref('');
	const tableData = ref([]);
	const jsjDialogVisible = ref(false);//详情
	const billid = ref('');
	const forbidEdit = ref(true);
	provide('forbidEdit', forbidEdit);
	provide('billid', billid);
	const orderForm = ref({
		mc:'',
		cllx:'',
		logo:'',
		fwts:'',
		disprice:'',
		minprice:'',
		pp:'',
		color:'',
		cph:'',
		zt:'',
		bz:'',
		ykj:''
	})
	provide('orderForm', orderForm);
	onMounted(async()=>{
		const response = await axios.post('/manageOrder/order/queryList', {
		  pageNo:pageNo.value,
		  pageSize:pageSize.value,
		}, {
		  headers: {
		    'Content-Type': 'application/json',
			Authorization: `${userStore.profile?.token}`,
		  }
		});
		if(response.data.status===0){
			tableData.value = response.data.data;
			total.value = response.data.total;
		}
	})
	const fetchOrders = async () => {
		searchType.value = 0;
		const response = await axios.post('/manageOrder/order/queryList', {
		  type: type.value,
		  key:key.value,
		  pageNo:pageNo.value,
		  pageSize:pageSize.value,
		}, {
		  headers: {
		    'Content-Type': 'application/json',
			Authorization: `${userStore.profile?.token}`,
		  }
		});
		if(response.data.status===0){
			tableData.value = response.data.data;
			total.value = response.data.total;
		}
	};
	const handleDtClick = (e)=>{
		billid.value = e.id;
		jsjDialogVisible.value = true;
	}
	const deleteData=async(e)=>{
		const response = await axios.post('/manageOrder/order/delete', {
		  billid:e.atddh,
		}, {
		  headers: {
		    'Content-Type': 'application/json',
			Authorization: `${userStore.profile?.token}`,
		  }
		});
		if(response.data.status===0){
			ElMessage({
				message: '删除成功!',
				type: 'success',
			})
			fetchOrders()
		}
		
	}
	const changePageNo = (val) => {
		pageNo.value = val;
		fetchOrders();
	}
</script>

<style scoped>
	.pagination-custom{
		display: flex;
		justify-content: flex-end;
		align-items: center;
		margin: 10px 0;
	}
	.search-jsj-tatle{
		float: right;
		width: 50%;
		margin: 0 0 10px 0;
	}
	.dialog-cond-dt{
		margin: 0 0 0 20px;
	}
	.dialog-cond-label{
		margin: 0 5px;
	}
</style>